<template>
    <div class="artice_top widthCen">
        <h6>当前位置：文章列表</h6>
        <div class="article_white">
            <ul>
                <li
                    class="fade-transform-leave-active"
                    v-for="(item, index) in list"
                    :key="index"
                    @click="details(item.Id)"
                >
                    <div class="artive_img">
                        <img
                            :src="item.ImgUrl || require('../../images/seat.png')"
                            alt
                        />
                    </div>
                    <div class="artive_hgroup">
                        <h6>{{ item.Title }}</h6>
                        <p>{{ item.SubTitle }}</p>
                    </div>
                </li>
            </ul>
            <!-- 分页 -->
            <div class="artice_page zhong center">
                <el-pagination
                    :current-page.sync="rq.page"
                    :page-size="rq.pageSize"
                    background
                    layout="prev, pager, next"
                    :total="totalCount"
                    @current-change="handleCurrentChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/artice.scss';
</style>
<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            list: [
            ],
            rq: {
                page: 1,
                pageSize: 5,
                recommend: 0
            },
            totalCount: 0
        };
    },
    created() {
        this.getList();
    },
    methods: {
        details(id) {
            this.$router.push('./articleDetails/' + id);
        },
        handleCurrentChange(val) {
            this.getList();
        },
        getList() {
            this.$api.getArticleList(this.rq).then(res => {
                // console.log('res:', res);
                this.list = res.data;
                this.totalCount = res.TotalCount;
                this.$nextTick(() => {
                    document.documentElement.scrollTop = document.body.scrollTop = 0;
                });
            });
        }
    }
};
</script>
